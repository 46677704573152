@import './abstracts/_mixins';
@import './abstracts/_variables';
@import './components/title';
@import './components/button';

/* **************************** Categories **************************** */
.categories {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: var(--toc-grey);

    //OVERRIDE BOOTSTRAP
    @media (min-width: 1200px) {
        .container {
            max-width: 1620px;
        }
    }

    .swiper-button {
        position: absolute;
        padding: 0 1.5rem;
        color: var(--toc-orange);
        border: none;
        background: none;
        z-index: 1;

        &-prev {
            left: 1.5rem;
        }

        &-next {
            right: 1.5rem;
        }
    }

    &__title {
        @include h2();
    }

    &__description {
        color: var(--toc-brown);

        &__btn--showing-datas {
            border: none;
            background: none;
            padding: 0;
        }
    }

    .subcategories {
        &__list {
            margin: 0;

            .subcategory {
                float: left;
                text-align: center;
                margin: 0 1rem;
                width:300px !important;

                &__item {
                    &__link {
                        @include flex-column();

                        &:hover {
                            text-decoration: none;
                        }

                        & img {
                            margin: auto;
                            max-width: 100%;
                            vertical-align: top;
                            border: 1px solid transparent;
                            border-radius: 50%;
                        }
                    }

                    & h2 {
                        font: 400 18px/22px "Montserrat", sans-serif;
                        color: var(--toc-brown);
                        text-transform: uppercase;

                        &:hover {
                            color: #ea6e0e;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    @media (max-width : $breakpoint-mobile) {
        .swiper-button {
            display: block;
            width: auto;
            padding: 0;
        }

        .subcategories {
            &__list {
                .subcategory {
                    margin: 0;
                    width: 250px !important;
                }
            }
        }
    }

    @media (max-width : $breakpoint-md) {
        &__title {
            display: none;
        }
    }
}